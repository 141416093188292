import React from 'react'

import { translate } from '@/application/utils/Translate/TranslateUtil'
import { useTermsConditionsDialogRules } from '@/presentation/dialogs/TermsConditions/TermsConditionsDialog.rules'
import {
  TermsConditionsDialogTitleSkeleton,
  TermsConditionsDialogContentSkeleton,
} from '@/presentation/dialogs/TermsConditions/TermsConditionsDialog.skeleton'
import { ITermsConditionsDialogProps } from '@/presentation/dialogs/TermsConditions/TermsConditionsDialog.types'
import {
  BoxComponent,
  ButtonComponent,
  DialogComponent,
} from '@codebox-team/web/components'

export const TermsConditionsDialog: React.FC<
  ITermsConditionsDialogProps
> = props => {
  const {
    data,
    isWaiting,
    isForcedDisabled,
    isSubmitting,
    isSubmittingAgreed,
    isSubmittingDecline,
    handleDecisionTerms,
  } = useTermsConditionsDialogRules(props)

  return (
    <DialogComponent
      testID="Terms conditions dialog"
      headerTitle={
        isWaiting ? <TermsConditionsDialogTitleSkeleton /> : data?.data?.title
      }
      open={props.isOpen}
      actions={
        <BoxComponent display="flex" paddingX="24px" paddingY="16px" gap="12px">
          <ButtonComponent
            testID="Terms conditions dialog decline button"
            onClick={() => handleDecisionTerms(false)}
            color="error"
            disabled={isForcedDisabled || isWaiting || isSubmitting}
            loading={isSubmittingDecline}
            variant="outlined">
            {translate.t('generic.labels.reject')}
          </ButtonComponent>
          <ButtonComponent
            testID="Terms conditions dialog agreed button"
            color="primary"
            variant="contained"
            loading={isSubmittingAgreed}
            disabled={isForcedDisabled || isWaiting || isSubmitting}
            onClick={() => handleDecisionTerms(true)}>
            {translate.t('generic.labels.confirm')}
          </ButtonComponent>
        </BoxComponent>
      }
      fullWidth
      onClose={() => !isWaiting && !isSubmitting && handleDecisionTerms(false)}>
      <BoxComponent paddingX="24px" paddingBottom="16px">
        {isWaiting ? (
          <TermsConditionsDialogContentSkeleton />
        ) : (
          data?.data?.content
        )}
      </BoxComponent>
    </DialogComponent>
  )
}
