import { getCurrentLocale } from '@/application/utils/Translate/TranslateUtil'
import { TokenConfig } from '@codebox-team/core'
import {
  Store,
  applyMiddleware,
  composeWithDevTools,
  createStore,
  persistReducer,
  persistStore,
  Action,
} from '@codebox-team/redux'
import { createSagaMiddleware } from '@codebox-team/redux-saga'
import {
  ReducerConfig,
  SagaConfig,
  TStoreState,
  UserDetailsCreators,
  userDetailsCredentialsResponse,
  userResponseClear,
  accessRightsHttpClient,
  fileManagerHttpClient,
  platformHttpClient,
  socialMediaHttpClient,
  HttpClientConfig,
  makeAuthenticationRefreshTokenFactory,
} from '@move-share-celebrate/core'

import storage from 'redux-persist/lib/storage'

export const PersistedReducer = persistReducer(
  {
    key: '@move-share-celebrate',
    storage,
    whitelist: ['user'],
  },
  ReducerConfig,
)

export const SagaMiddleware = createSagaMiddleware()
export const middlewares = [SagaMiddleware]

export const RootReducer = (state: TStoreState, action: Action) => {
  if (action.type === UserDetailsCreators.EActionTypes.RESPONSE_CLEAR) {
    return PersistedReducer(
      {
        _persist: state._persist,
      } as TStoreState,
      action,
    )
  }

  return PersistedReducer(state, action)
}

export const store: Store<TStoreState> = createStore(
  RootReducer,
  composeWithDevTools(applyMiddleware(...middlewares)),
)

const tokenService = new TokenConfig({
  getTokenHandler: () => {
    return new Promise(resolve => {
      const credentials = store?.getState?.()?.user?.details?.credentials

      resolve({
        accessKey: credentials?.accessKey || '',
        refreshKey: credentials?.refreshKey || '',
      })
    })
  },
  refreshTokenSuccessHandler: credentials => {
    store.dispatch(userDetailsCredentialsResponse({ response: credentials }))
  },
  refreshTokenFailureHandler: () => {
    store.dispatch(userResponseClear())
  },
})

HttpClientConfig.addLocale(getCurrentLocale())
HttpClientConfig.addTokenService(tokenService)
HttpClientConfig.addTokenRefreshService(makeAuthenticationRefreshTokenFactory())

accessRightsHttpClient.applyInterceptors()
fileManagerHttpClient.applyInterceptors()
platformHttpClient.applyInterceptors()
socialMediaHttpClient.applyInterceptors()

export const persistor = persistStore(store)
SagaMiddleware.run(SagaConfig)
